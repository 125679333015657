import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import {
  AvtaleResponse,
  AvtaleUpdateResponse,
  Nettleie12MndResponse
} from 'src/app/services/avtale/avtale.types';
import { theTime } from 'src/app/utils/date-utils';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AvtaleService {
  constructor(
    private _httpClient: HttpClient,
    private analytics: AnalyticsService
  ) { }

  getAvtaler(date?: Date): Observable<AvtaleResponse> {
    return this._httpClient
      .get<AvtaleResponse>(
        'https://apiprivat.ishavskraft.no/avtale/' +
        (date ? theTime(date).toPlainDateString() : '')
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.analytics.logEvent_errorFetch(
            'https://apiprivat.ishavskraft.no/avtale',
            `Status ${error.status} ${error.statusText} - ${error.url}`
          );
          return of(null);
        })
      );
  }

  updateAvtale(payload: string): Observable<AvtaleUpdateResponse> {
    //var headers = {
    //    headers: new HttpHeaders({
    //        'Content-Type': 'application/json'
    //    })
    //}

    return this._httpClient.put<AvtaleUpdateResponse>(
      'https://apiprivat.ishavskraft.no/avtale/',
      payload
    );
  }

  updateInvoiceMethod(payload: string): Observable<AvtaleUpdateResponse> {
    //var headers = {
    //    headers: new HttpHeaders({
    //        'Content-Type': 'application/json'
    //    })
    //}

    return this._httpClient.put<AvtaleUpdateResponse>(
      'https://apiprivat.ishavskraft.no/avtale/faktureringsmaate/',
      payload
    );
  }

  getNettleieSiste12Mnd() {
    return this._httpClient.get<Nettleie12MndResponse>(
      'https://apiprivat.ishavskraft.no/avtale/nettleie/sistetolvmnd'
    );
  }
}
