import type { AirDatepickerLocale } from 'air-datepicker';
import { theTime, type TimeDate } from "./the-time";
import { addHours, addDays, addMonths, addYears, getDaysInMonth } from "date-fns";

export { theTime, addHours, addDays, getDaysInMonth };
export const addMonth = addMonths;
export const addYear = addYears;

export const months = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember'
];

export const monthsShort = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAI',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OKT',
  'NOV',
  'DES'
];

export const getMonthNameByIndex = (month: Date) => months[month.getMonth()];

// TODO: check use of this, return string is strange
export function formatDateToString(
  dateString: string,
  year: 'full' | 'short' | 'none' = 'full'
) {
  const date = theTime(dateString);
  const yearString =
    year === 'none'
      ? ''
      : year === 'short'
        ? '.' + date.getFullYear().toString().slice(-2)
        : '.' + date.getFullYear();
  return (
    date.get("DD") +
    '.' +
    date.get("MM") +
    yearString
  );
}

/**
 * Date object to "yyyymm".
 * @param d: Date
 * @return string -- "yyyymm" (length 6) */
export const yyyymm = (d = new Date()): string => [
  d.getFullYear(),
  String(d.getMonth() + 1).padStart(2, "0"),
].join("");

/**
 * Date object to "yyyymmdd".
 * @param d: Date
 * @return string -- "yyyymmdd" (length 8) */
export const yyyymmdd = (d = new Date()): string => [
  d.getFullYear(),
  String(d.getMonth() + 1).padStart(2, "0"),
  String(d.getDate()).padStart(2, "0"),
].join("");

/**
 * Date object to "yyyy-mm".
 * @param d: Date
 * @return string -- "yyyy-mm" (length 7) */
export const isoMonth = (d = new Date()): string => [
  d.getFullYear(),
  String(d.getMonth() + 1).padStart(2, "0"),
].join("-");

/**
 * Date object to "yyyy-mm-dd".
 * @param d: Date
 * @return string -- "yyyy-mm-dd" (length 10) */
export const isoDay = (d = new Date()): string => [
  d.getFullYear(),
  String(d.getMonth() + 1).padStart(2, "0"),
  String(d.getDate()).padStart(2, "0"),
].join("-");
// Legacy function name. Deprecate?
export const dayString = isoDay;
export const formatDateToISOString = isoDay;

export const CustomLocaleNb: AirDatepickerLocale = {
  days: [
    'Søndag',
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag'
  ],
  daysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
  daysMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
  months: [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember'
  ],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des'
  ],
  today: 'I dag',
  clear: 'Tøm',
  dateFormat: 'dd.MM.yyyy',
  timeFormat: 'hh:ii',
  firstDay: 1
};

export const formatYYYYMMDDNumberToDate = (val: number): Date => {
  const YYYY = String(val).substring(0, 4);
  const MMDD = String(val).replace(YYYY, '');
  let MM = MMDD.substring(0, 2);
  MM = Number(MM) > 12 ? MMDD.substring(0, 1) : MM;
  const regexMonthStartOfString = new RegExp(`^${MM}`);
  const DD = MMDD.replace(regexMonthStartOfString, '');

  return theTime({ YYYY, MM, DD });
};

export const formatYYYYMMNumberToDate = (val: number): Date => {
  const YYYY = String(val).substring(0, 4);
  const MM = String(val).replace(YYYY, '');

  return theTime({ YYYY, MM });
};

export function formatYYYYMMDDHHNumberToDate(value: number): Date {
  const str = value.toString();
  return new Date(
    Number(str.substring(0, 4)),
    Number(str.substring(4, 6)) - 1,
    Number(str.substring(6, 8)),
    Number(str.substring(8, 10)),
    0,
    0
  );
}

export const getHourList = (fromDate = new Date()): number[] => {
  const d = theTime(fromDate, { HH: 0 });
  // Recursive function to get all hours from Date
  const getAllHours = (d: TimeDate, ret: number[] = []): number[] => {
    ret.push(+d.to("HH"));
    const next = d.goes({ HH: 1 });
    const isSameDay = next.getDate() === d.getDate();
    return isSameDay ? getAllHours(next, ret) : ret;
  };
  let result: number[] = getAllHours(d);
  return result;
};
