import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Device } from '@capacitor/device';
import { FcmTokenService } from '../fcm-token/fcm-token.service';
import { AlertController, Platform } from '@ionic/angular';
import { analyseRoute, profilRoute, spotprisRoute } from 'src/app/routes';
import { Preferences } from '@capacitor/preferences';
import { mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DataService } from '../datatransfer/DataService';
import { AvtaleService } from '../avtale/avtale.service';
import { NavigationExtras, Router } from '@angular/router';
import { addDays } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  subscription: Subscription;
  kunde_id: string;
  subscribeToOmraader: string[];

  constructor(
    private _fcmToken: FcmTokenService,
    private _alertController: AlertController,
    private _platform: Platform,
    private dataService: DataService,
    private avtaleService: AvtaleService,
    private router: Router
  ) {
    this.setupDataChangeListener();
  }

  initializePushNotifications = async (
    kunde_id: string,
    omraader: string[]
  ) => {
    this.kunde_id = kunde_id;
    this.subscribeToOmraader = omraader;

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });

    // Try clearing all listeners before adding
    await PushNotifications.removeAllListeners();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      const deviceId = await Device.getId();
      const info = await Device.getInfo();
      const deviceName = deviceId.identifier + ':' + info.name;
      const fcmToken = await FCM.getToken();

      // FCM token hentes fra ulik kilde på ulik plattform
      const tokenToStore = this._platform.is('android')
        ? token.value
        : fcmToken.token;

      this._fcmToken
        .getFcmTokenWithPreferences(deviceName, this.kunde_id)
        .pipe(
          mergeMap((res) => {
            // Subscribe token to topic
            if (res.preferences?.tomorrowSpot === true) {
              this.subscribeToMyOmraader();
            }
            const storedToken = res?.token?.regToken;
            const tokenDate = new Date(res?.token?.insertDateTime);
            // Renew token date every 30 days, since stale tokens are deleted after 60 days
            const timeToRenewToken =
              storedToken === tokenToStore &&
              tokenDate &&
              tokenDate < addDays(new Date(), -30);

            if (
              !storedToken ||
              storedToken !== tokenToStore ||
              timeToRenewToken
            ) {
              // Post new token to DB
              const payload = JSON.stringify({
                RegToken: tokenToStore,
                KundeId: this.kunde_id,
                DeviceName: deviceName
              });
              return this._fcmToken.postFcmToken(payload);
            } else {
              return of(null);
            }
          })
        )
        .subscribe();
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      // TODO: håndtere dette på en bedre måte?
      alert(
        'Fikk ikke til å skru på pushvarsler. Prøv igjen under Profil. Feil: ' +
          JSON.stringify(error)
      );
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        if (notification.data?.page && notification.data?.page === 'spot') {
          this.showSpotprisAvailableAlert();
        }
        if (notification.data?.page && notification.data?.page === 'analyse') {
          this.showAnalyseAlert(notification.data);
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // Vent på kald oppstart før vi redirecter
        new Promise((f) => setTimeout(f, 500)).then(() => {
          if (
            notification.actionId === 'tap' &&
            notification.notification.data?.page
          ) {
            if (notification.notification.data?.page === 'spot') {
              let navigationExtras: NavigationExtras = {
                state: {
                  activeChart: 'Time',
                  presentation: 'table'
                }
              };
              this.router.navigate([spotprisRoute], navigationExtras);
            }
            if (notification.notification.data?.page === 'analyse') {
              let navigationExtras: NavigationExtras = {
                state: {
                  objectid: notification.notification.data?.objectid || ''
                }
              };
              this.router.navigate([analyseRoute], navigationExtras);
            }
          }
        });
      }
    );
  };

  async subscribeToTopic(topic: string) {
    await FCM.subscribeTo({ topic });
  }

  async unsubscribeFromTopic(topic: string) {
    await FCM.unsubscribeFrom({ topic });
  }

  subscribeToMyOmraader() {
    this.subscribeToOmraader?.map((omraade) => {
      this.subscribeToTopic(`${omraade}-spot`);
    });
  }

  unsubscribeFromMyOmraader() {
    this.subscribeToOmraader?.map((omraade) => {
      this.unsubscribeFromTopic(`${omraade}-spot`);
    });
  }

  async unsubscribeFromAllSpotTopics() {
    // Håndtere uforutsette feil etter kundebytte / endring av anlegg
    await FCM.unsubscribeFrom({ topic: 'test-spot' });
    await FCM.unsubscribeFrom({ topic: 'NO1-spot' });
    await FCM.unsubscribeFrom({ topic: 'NO2-spot' });
    await FCM.unsubscribeFrom({ topic: 'NO3-spot' });
    await FCM.unsubscribeFrom({ topic: 'NO4-spot' });
    await FCM.unsubscribeFrom({ topic: 'NO5-spot' });
  }

  async showNotificationInfoAlert() {
    // Check if info message has been shown before
    const { value } = await Preferences.get({
      key: 'hasReadNotificationMessage'
    });
    if (!value || value === 'false') {
      const initalert = await this._alertController.create({
        cssClass: 'push-alert',
        mode: 'ios',
        header: 'Nyhet!',
        message:
          'Nå kan du få pushvarslinger i appen når morgendagens spotpriser er tilgjengelige, eller hvis du bruker mye strøm samtidig og får en høy nettleie-døgnmaks. Gå til Profil for å administrere varsler.',
        buttons: [
          {
            text: 'Avbryt',
            role: 'cancel'
          },
          {
            text: 'Profil',
            role: 'confirm',
            handler: () => {
              this.router.navigate([profilRoute]);
            }
          }
        ]
      });
      await initalert.present();

      await Preferences.set({
        key: 'hasReadNotificationMessage',
        value: 'true'
      });
    }
  }

  async showSpotprisAvailableAlert() {
    // Check if info message has been shown before
    const initalert = await this._alertController.create({
      cssClass: 'push-alert',
      mode: 'ios',
      header: 'Se morgendagens spotpris!',
      message: 'Morgendagens spotpris er nå tilgjengelig under Spot.',
      buttons: [
        {
          text: 'Lukk',
          role: 'cancel'
        },
        {
          text: 'Se spotpris',
          role: 'confirm',
          handler: () => {
            this.router.navigate([spotprisRoute]);
          }
        }
      ]
    });
    await initalert.present();
  }

  async showAnalyseAlert(data) {
    const initalert = await this._alertController.create({
      cssClass: 'push-alert',
      mode: 'ios',
      header: data.title ?? 'Høy døgnmaks oppdaget',
      message: data.body ?? 'Se detaljer i analysen',
      buttons: [
        {
          text: 'Lukk',
          role: 'cancel'
        },
        {
          text: 'Gå til analyse',
          role: 'confirm',
          handler: () => {
            let navigationExtras: NavigationExtras = {
              state: {
                objectid: data.objectid || ''
              }
            };
            this.router.navigate([analyseRoute], navigationExtras);
          }
        }
      ]
    });
    await initalert.present();
  }

  setupDataChangeListener() {
    // Håndter kundebytte
    this.subscription = this.dataService.getKundebytte().subscribe((x) => {
      if (x) {
        this.unsubscribeFromMyOmraader();
        this.avtaleService.getAvtaler().subscribe({
          next: async (avtaleRes) => {
            if (avtaleRes.avtaler.length > 0) {
              this.kunde_id = avtaleRes.avtaler[0].kunde_id;
              this.subscribeToOmraader = avtaleRes.avtaler
                .map((a) => a.prisomrade)
                .filter(
                  (value, index, listOfOmrader) =>
                    listOfOmrader.indexOf(value) === index
                );
              PushNotifications.register();
              // this.subscribeToMyOmraader(); (skjer i register hvis vi vil ha varsler)
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    PushNotifications.removeAllListeners();
  }
}
